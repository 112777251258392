var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),(_vm.event && _vm.race)?_c('v-card',[_c('Header',{attrs:{"event":_vm.event}}),_c('v-card-title',{staticClass:"headline"},[_vm._v(" Tiers for "),_c('RaceSelector',{attrs:{"event":_vm.event,"race":_vm.race,"routeName":"eventmanagerRaceTiers"}})],1),_c('v-card-text',[_vm._v(" Customize the tiers(levels) for this leaderboard. ")]),_c('v-card-text',[_c('strong',[_vm._v("Tip:")]),_vm._v(" the tier with the highest value will show on top of the leaderboard. You can leave either the min or max value empty, but not both. ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tiers,"items-per-page":100,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mx-4",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{staticClass:"ml-0 subtitle"},[_vm._v("Tiers")]),_c('v-spacer')],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('DistanceTextArea',{attrs:{"unit":_vm.event.unit,"hideDetails":"","mode":_vm.$helpers.UnitType.NUMBER},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}},{key:"item.min_score",fn:function(ref){
var item = ref.item;
return [_c('DistanceTextArea',{attrs:{"unit":_vm.event.unit,"hideDetails":"","mode":_vm.$helpers.UnitType.NUMBER},model:{value:(item.min_score),callback:function ($$v) {_vm.$set(item, "min_score", $$v)},expression:"item.min_score"}})]}},{key:"item.max_score",fn:function(ref){
var item = ref.item;
return [_c('DistanceTextArea',{attrs:{"unit":_vm.event.unit,"hideDetails":"","mode":_vm.$helpers.UnitType.NUMBER},model:{value:(item.max_score),callback:function ($$v) {_vm.$set(item, "max_score", $$v)},expression:"item.max_score"}})]}},{key:"footer",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":_vm.addRow}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"gray"}},[_vm._v("fa-plus-circle")]),_vm._v(" Add a tier")],1),_c('span',{staticClass:"text-muted"},[_vm._v("(To delete a tier: clear the display name and press Save)")])]},proxy:true}],null,false,3980771868)}),_c('v-card-text',[_c('v-btn',{attrs:{"color":"primary","large":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }